import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Button, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Нотаріус Професіонал
			</title>
			<meta name={"description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:title"} content={"Нотаріус Професіонал"} />
			<meta property={"og:description"} content={"Ваш надійний юридичний партнер"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/667472bafdcae00021e3a639/images/6010ff4906ea5_1200x1200.webp?v=2024-06-20T18:33:21.976Z"} />
		</Helmet>
		<Components.Marque />
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="box3" />
			<Override slot="box1" />
			<Override slot="box" />
			<Override slot="icon" />
			<Override slot="icon1" />
			<Override slot="text3" />
			<Override slot="text2" />
		</Components.Hero>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text
					margin="0px 0px 25px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
				>
					<Strong>
						Наші послуги
					</Strong>
					{"\n"}
				</Text>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--base" width="70%">
					Ми надаємо повний спектр нотаріальних послуг, включаючи посвідчення угод, довіреностей, договорів, та оформлення спадщини. Наші фахівці мають великий досвід та завжди готові допомогти вам у вирішенні будь-яких юридичних питань. Ми працюємо для вас, щоб забезпечити юридичну чистоту та надійність ваших документів.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667472bafdcae00021e3a639/images/notarius.jpg?v=2024-06-20T18:33:21.971Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 0 0px"
							font="normal 600 20px/1.2 --fontFamily-sans"
							color="--light"
							text-align="center"
							height="auto"
						>
							<Strong margin="0px 0px 2rem 0px">
								Посвідчення угод
							</Strong>
							<br />
							<br />
						</Text>
						<Button type="link" text-decoration-line="initial" href="/services">
							Дізнатися більше
						</Button>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667472bafdcae00021e3a639/images/notarius-yuryst-rieltor-riznytsia.jpg?v=2024-06-20T18:33:21.976Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 2rem 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Оформлення спадщини
						</Text>
						<Button type="link" text-decoration-line="initial" href="/services">
							Дізнатися більше
						</Button>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667472bafdcae00021e3a639/images/Notarius02.jpg?v=2024-06-20T18:33:21.970Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 2rem 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Довіреності
						</Text>
						<Button type="link" text-decoration-line="initial" href="/services">
							Дізнатися більше
						</Button>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Чому обирають нас?
					</Strong>
					{"\n"}
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Наша команда складається з висококваліфікованих нотаріусів, які постійно підвищують свою кваліфікацію. Ми цінуємо ваш час, тому робимо все можливе для швидкого та якісного обслуговування. Вибираючи нас, ви отримуєте надійного партнера у всіх юридичних справах.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="--headline2"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					<Strong>
						Наш підхід до роботи
					</Strong>
					{"\n"}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 25px 0px">
						Ми працюємо індивідуально з кожним клієнтом, враховуючи всі його потреби та побажання. Наші нотаріуси дбають про конфіденційність вашої інформації та забезпечують її захист на всіх етапах роботи. Ми прагнемо створити максимально комфортні умови для співпраці.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					<Strong>
						Зручне розташування та графік роботи
					</Strong>
					{"\n"}
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Наша нотаріальна контора знаходиться в центрі міста, що робить нас доступними для всіх. Ми працюємо з понеділка по п’ятницю з 9:00 до 18:00, а також у суботу з 10:00 до 14:00. Ви можете записатися на прийом заздалегідь, щоб уникнути черг та зекономити свій час.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="--headline2"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					<Strong>
						Онлайн-консультації
					</Strong>
					{"\n"}
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="--dark" sm-margin="0px 0px 25px 0px">
						Для вашої зручності ми пропонуємо послугу онлайн-консультацій. Ви можете звернутися до наших нотаріусів через відеозв’язок, отримати консультацію та домовитися про необхідні нотаріальні дії. Це дозволяє швидко та ефективно вирішити ваші питання без необхідності відвідувати офіс.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Marque>
			<Override slot="text1" />
			<Override slot="text" />
		</Components.Marque>
		<Components.Header>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Header>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});